<template>
  <form @submit.prevent="formSubmit(handleSubmit)" novalidate class="home-screening-form">
    <transition name="small-slide" mode="out-in">
      <div class="home-screening-form__success" v-if="success" key="success">
        <alert type="success">Your screening request has been submitted. Please check your inbox for an email from our team. Make sure it did not end up in your spam.</alert>
      </div>

      <div v-else key="form">
        <v-text-field
          v-model="form.field_email"
          @input="delayTouch($v.form.field_email)"
          :error-messages="errorMessages('field_email', 'Email')"
          name="field_email"
          required
          aria-required="true"
          label="Email*"
          solo>
        </v-text-field>

        <!-- <v-text-field
          v-model="form.field_first_name"
          :error-messages="errorMessages('field_first_name', 'First Name')"
          name="field_first_name"
          required
          aria-required="true"
          label="First Name*"
          solo>
        </v-text-field>
        
        <v-text-field
          v-model="form.field_last_name"
          :error-messages="errorMessages('field_last_name', 'Last Name')"
          name="field_last_name"
          required
          aria-required="true"
          label="Last Name*"
          solo>
        </v-text-field>

        <v-select
          v-model="$v.form.field_country.$model"
          :error-messages="errorMessages('field_country', 'Country')"
          required
          aria-required="true"
          name="field_country"
          :items="countryItems"
          label="Country*"
          solo
          :menu-props="{ maxHeight: 275 }">
        </v-select>
        
        <v-text-field
          v-model="$v.form.field_city.$model"
          :error-messages="errorMessages('field_city', 'City')"
          required
          aria-required="true"
          name="field_city"
          label="City*"
          solo>
        </v-text-field>

        <v-text-field
          v-model="$v.form.field_state.$model"
          :error-messages="errorMessages('field_state', 'State / Province / Region')"
          required
          aria-required="true"
          name="field_state"
          label="State / Province / Region*"
          solo>
        </v-text-field> -->

        <!-- <v-checkbox
          class="mt-0 mb-1"
          v-model="subscribe"
          label="Email me updates about THE WEST IS BURNING"
        /> -->

        <v-btn
          type="submit"
          :loading="loading"
          color="accent"
          depressed
          class="btn-extra-wide"
          :disabled="!!success">
          Register & Host
        </v-btn>

        <div class="form__alerts">
          <transition name="small-slide" mode="out-in">
            <alert v-if="error" type="error">
              {{ error }}
            </alert>
          </transition>
        </div>

        <recaptcha-policy />
      </div>
    </transition>
  </form>
</template>

<script>
import formMixin from '@/mixins/formMixin'
import subscribeUserMixin from '@/mixins/subscribeUserMixin'
import contentTypeApi from '@/api/contentType'
import { required, email } from 'vuelidate/lib/validators'
import { COUNTRIES } from '@/utils/constants'
import { mapActions } from 'vuex'

export default {
  name: 'home-screening-form',
  
  mixins: [formMixin, subscribeUserMixin],

  data() {
    return {
      subscribe: false,
      countryItems: COUNTRIES,
      form: {
        title: 'Private Screening',
        field_email: '',
        field_first_name: '',
        field_last_name: '',
        field_screening_type: 'Private',
        field_city: '',
        field_zip_code: '',
        field_country: 'United States',
        field_state: ''
      },
      type: 'screening'
    }
  },
  
  methods: {
    ...mapActions(['generalEvent']),
    handleInvalidForm () {
      this.$nextTick(() => {
        this.$vuetify.goTo('.v-text-field.error--text', {
          offset: 100
        })
      })
    },
    handleValidSubmission () {
      setTimeout(() => {
        this.$vuetify.goTo('.home-screening-form', {
          offset: 100
        })
      }, 500)
    },
    async handleSubmit() {
      this.error = null
  
      try {
        await contentTypeApi.post(this.type, {
          ...this.form,
          title: `Private Screening for ${this.form.field_email}`,
          field_spam: this.possibleRobot
        })

        this.generalEvent({
          category: 'host',
          action: 'submit-success',
          label: 'Private Screening'
        })

        this.handleValidSubmission()

        try {
          this.subscribe && await this.handleSubscribeUser({
            email: this.form.field_email,
            name: `${this.form.field_first_name} ${this.form.field_last_name}` 
          })
        } catch (e) {
          console.error(e)
        }

        this.success = true
        this.resetForm()
      } catch (error) {
        this.success = false
        this.error = 'Uh oh, not sure what happened. Please try again.'
        
        throw error
      }
    }
  },

  validations: {
    form: {
      field_email: { required, email },
      // field_first_name: { required },
      // field_last_name: { required },
      field_screening_type: { required },
      // field_country: { required },
      // field_state: { required },
      // field_city: { required }
    }
  }
}
</script>

<style lang="stylus">
</style>
