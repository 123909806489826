<template>
  <view-wrapper class="home-screening" header-text="Host <span class='underline'>private</span> screening with free discussion guide">
    <p class="mb-5">
      We invite you to sign up to host free home screenings of <strong>THE WEST IS BURNING</strong> in your community. With our complimentary viewing guide, we are committed to supporting your disucssions and your participation. Thank you for being part of the conversation.
    </p>
    <ul class="mb-5">
      <li><number-label number="1">Register below</number-label></li>
      <li><number-label number="2">Check your inbox for free screening guide</number-label></li>
      <li><number-label number="3">Invite guests</number-label></li>
      <li><number-label number="4">Gather, screen, and discuss</number-label></li>
      <li><number-label number="5">Continue the conversation</number-label></li>
    </ul>

    <home-screening-form></home-screening-form>
  </view-wrapper>
</template>

<script>
import HomeScreeningForm from '@/components/_forms/HomeScreeningForm/HomeScreeningForm'

export default {
  name: 'home-screening',

  components: {
    HomeScreeningForm
  }
}
</script>

<style lang="stylus">
  .home-screening
    ul
      margin: 0
      padding: 0
      list-style: none

      .number-label
        margin-bottom: 24px  
</style>
